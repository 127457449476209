.dashboard {
  width: 100%;
}

.panel-wrapper {
  width: 100%;
}
.container {
  padding: 2rem;
  width: 100%;

  background-color: #f7f7f7;
}

.container-wrapper {
  padding: 20px;
  margin: 20px;
  min-height: 50rem;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

.country {
  display: flex;
  align-items: center;

  gap: 1rem;

  .change {
    padding: 1px 20px;

    background-color: lightgray;
  }

  .active {
    font-weight: 700;
  }
}

.settings-nav {
  margin-top: 1rem;
  margin-bottom: 2rem;

  .nav-section {
    width: 13rem;

    h3 {
      padding-bottom: .8rem;
      padding-left: 1rem;

      font-size: 1rem;
      color: #666666;
    }

    ul {
      padding: 0;
      margin: 0;

      list-style: none;

      li {
        padding-top: .5rem;
        padding-right: 2rem;
        padding-bottom: .5rem;
        padding-left: 1rem;

        cursor: pointer;

        a {
          font-size: .9rem;
          color: #333333;
          text-decoration: none;

          &:hover {
            color: #0066ff;
          }
        }

        &.active {
          background-color: #1f1a54;
          border-radius: 8px;

          a {
            color: #f7f7f7;
          }
        }
      }
    }
  }
}

.settings-content {
  padding: 20px;

  h2 {
    margin-bottom: 20px;

    font-size: 1.5rem;
  }
}

.feature-control-content,
.privacy-config-content,
.promos-discount-content {
  background: #ffffff;
  border-radius: 8px;
}

.control-container {
  display: flex;
  margin-top: 2rem;

  gap: 2rem;
}

h2 {
  margin: 0;
}

.features-list {
  margin-top: 20px;
  width: 30rem;
}

.feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  border-bottom: 1px solid #eeeeee;
}

.feature-name {
  font-size: 16px;
}

.switch {
  position: relative;

  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  width: 0;
  height: 0;

  opacity: 0;
}

.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: #cccccc;
  border-radius: 34px;

  cursor: pointer;
  transition: .4s;
}

.slider:before {
  content: "";

  position: absolute;
  bottom: 4px;
  left: 4px;

  width: 26px;
  height: 26px;

  background-color: white;
  border-radius: 50%;

  transition: .4s;
}

input:checked + .slider {
  background-color: #ffd403;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.privacy-config-content {
  padding: 20px;

  .editor-container {
    margin-top: 20px;

    background: white;
    border: 1px solid #dddddd;
    border-radius: 4px;

    .title-input {
      padding: 15px;

      border-bottom: 1px solid #dddddd;

      input {
        padding: 8px;
        width: 100%;

        font-size: 16px;

        border: 1px solid #dddddd;
        border-radius: 4px;
        outline: none;

        &:focus {
          border-color: #2196f3;
        }
      }
    }

    .quill {
      .ql-toolbar {
        border: none;
        border-bottom: 1px solid #dddddd;
      }

      .ql-container {
        height: 300px;

        border: none;
      }

      .ql-editor {
        min-height: 300px;

        font-size: 14px;
        line-height: 1.6;
      }
    }
  }
}
